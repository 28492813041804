import React from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import Section from 'app/common/Section';
import StripedTable from 'app/common/StripedTable';
import {
  renderCellExpand,
  renderCellExpandNumber,
} from 'app/common/GridCellExpand';
import { KPIGroup, KPI, KPIValue, Control } from 'app/services/api';
import { UserMap } from 'app/utils/helpers';
import { controlsState } from 'app/common/actions/controls';
import { threatLevelState } from 'app/common/actions/threat-levels';
import { threatSurfaceState } from 'app/common/actions/threat-surfaces';
import { ThreatLevel, ThreatSurface } from 'app/services/apiThreatSurfaces';

const ONE_DAY_MS = 86400000;

type AnalysisTableValues = {
  id: number;
  controlName: string;
  kpiName: string;
  level: string;
  surface: string;
  kpiValue: number;
  user: string;
  lastUpdate: Date | null;
  daysSinceLastUpdate: number;
};

type AnalysisTableType = {
  kpiGroups: KPIGroup[];
  controls: controlsState;
  securityCoverageData: KPI[][] | null;
  userData: UserMap | null;
  threatLevels: threatLevelState;
  threatSurfaces: threatSurfaceState;
};

const AnalysisTable = ({
  kpiGroups,
  controls,
  securityCoverageData,
  userData,
  threatLevels,
  threatSurfaces,
}: AnalysisTableType): JSX.Element => {
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.2, hide: true },
    {
      field: 'controlName',
      headerName: 'Control',
      flex: 1.8,
      renderCell: renderCellExpand,
    },
    {
      field: 'kpiName',
      headerName: 'KPI Name',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'level',
      headerName: 'Level',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'surface',
      headerName: 'Surface',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'kpiValue',
      type: 'number',
      headerName: 'KPI Value (%)',
      flex: 1,
      renderCell: renderCellExpandNumber,
    },
    {
      field: 'user',
      headerName: 'User',
      description: 'Updated by',
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'lastUpdate',
      type: 'date',
      headerName: 'Date',
      description: 'Last update date',
      flex: 0.7,
    },
    {
      field: 'daysSinceLastUpdate',
      type: 'number',
      headerName: 'Last Updated',
      description: 'Days since last update',
      flex: 1,
      renderCell: renderCellExpandNumber,
    },
  ];

  const groupName = 'security-coverage';
  const kpiGroup = kpiGroups.find((grp: KPIGroup) => grp.path === groupName);

  const controlsPayload = controls.payload as Control[];
  const tSurfacePayload = threatSurfaces.payload as ThreatSurface[];
  const tLevelPayload = threatLevels.payload as ThreatLevel[];

  if (
    !kpiGroup ||
    !securityCoverageData ||
    (Array.isArray(securityCoverageData) && !securityCoverageData.length)
  ) {
    return <div>No KPIs found</div>;
  }

  let idx = 0;
  const now = Date.now();
  const controlKpis: AnalysisTableValues[] = [];
  securityCoverageData.forEach((controlSubGroupKpis: KPI[]) => {
    controlSubGroupKpis.forEach((kpi: KPI) => {
      if (kpi.kpiValue && kpi.kpiValue.length > 0) {
        kpi.kpiValue.forEach((kpiValue: KPIValue) => {
          // control name
          const control: Control | undefined = controlsPayload.find(
            (c) => c.id === kpiValue.controlId
          );
          const controlName = control ? control.name : '';
          // surface name
          const surface: ThreatSurface | undefined = tSurfacePayload.find(
            (s) => s.id === kpiValue.surfaceId
          );
          const surfaceName = surface ? surface.name : '';
          //level name
          const level: ThreatLevel | undefined = tLevelPayload.find(
            (s) => s.id === kpiValue.levelId
          );
          const levelName = level ? level.name : '';
          // user name
          let userName = '';
          if (kpiValue.userId) {
            if (userData && kpiValue.userId in userData) {
              userName = userData[kpiValue.userId].name;
            } else {
              userName = kpiValue.userId.toString();
            }
          }
          // date
          let updateDate = null;
          if (kpiValue.createdTime) {
            updateDate = new Date(kpiValue.createdTime);
          }
          // last updated days
          let days = 0;
          if (kpiValue.createdTime) {
            const lastUpdatedTimestamp = Date.parse(kpiValue.createdTime);
            if (lastUpdatedTimestamp > 0) {
              const deltaMs = now - lastUpdatedTimestamp;
              days = Math.floor(deltaMs / ONE_DAY_MS);
            }
          }

          const controlKpi: AnalysisTableValues = {
            id: idx,
            controlName: controlName,
            kpiName: kpi.name,
            level: levelName,
            surface: surfaceName,
            kpiValue: Number(kpiValue.value),
            user: userName,
            lastUpdate: updateDate,
            daysSinceLastUpdate: days,
          };
          controlKpis.push(controlKpi);
          idx += 1;
        });
      }
    });
  });

  return (
    <Section>
      <StripedTable>
        <StripedTable.Head>
          <StripedTable.Heading colSpan={1} helpURI="">
            {kpiGroup.name} Analysis
          </StripedTable.Heading>
        </StripedTable.Head>
      </StripedTable>
      <DataGrid
        rows={controlKpis}
        columns={columns}
        autoHeight={true}
        disableColumnMenu={true}
        hideFooterPagination={false}
        checkboxSelection={true}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </Section>
  );
};

export default AnalysisTable;
