import React from 'react';
import { displayHelp } from 'app/services/help';
import { KPI } from 'app/services/api';
import { ProcessedControlCapability as CapabilityType } from 'app/utils/helpers';
import { ThreatSurface, ThreatLevel } from 'app/services/apiThreatSurfaces';
import { getBarColorsForValue } from 'app/common/BarGraph';
import DescriptionAccordion from 'app/common/DescriptionAccordion';
import {
  tableContainerWithMarginStyles,
  headerStyles,
  firstColStyles,
} from 'app/kpi/components/tables';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { HelpOutlineRounded } from '@material-ui/icons';

type ThreatSurfaceMatrixType = {
  securityGroupValues: KPI[] | null;
  capabilities: CapabilityType[];
  threatSurface: ThreatSurface;
  threatLevels: ThreatLevel[];
};

const featureName =
  'dashboard/control-dashboards/security/threat-preparedness/threat-surface-matrix';

const ThreatSurfaceMatrix = ({
  securityGroupValues,
  capabilities,
  threatSurface,
  threatLevels,
}: ThreatSurfaceMatrixType) => {
  const securityCoverage = securityGroupValues?.filter(
    (group) => group.key === 'com.pharossecurity.security-coverage.coverage'
  )[0];
  const securityScope = securityGroupValues?.filter(
    (group) => group.key === 'com.pharossecurity.security-coverage.scope'
  )[0];

  // Starting from the first threat level
  const data =
    Array.isArray(threatLevels) &&
    threatLevels.map((tLevel) => {
      const rowData: any = [];

      const capability = capabilities.find(
        (cap: CapabilityType) =>
          cap.levelId === tLevel.id &&
          cap.surfaceTypeId === threatSurface.surfaceTypeId
      );
      const scope =
        securityScope?.kpiValue.find(
          (kpi: any) =>
            kpi?.levelId === capability?.levelId &&
            kpi?.surfaceId === threatSurface.id &&
            kpi?.kpi.key.split('.').pop().includes('scope')
        ) || [];
      const coverage =
        securityCoverage?.kpiValue.find(
          (kpi: any) =>
            kpi?.levelId === capability?.levelId &&
            kpi?.surfaceId === threatSurface.id &&
            kpi?.kpi.key.split('.').pop().includes('coverage')
        ) || [];

      const scopeValue = !Array.isArray(scope) ? Number(scope.value) : 0;
      const coverageValue = !Array.isArray(coverage)
        ? Number(coverage.value)
        : 0;

      rowData.push(
        `${tLevel.severity}. ${tLevel.name}`,
        capability
          ? {
              summary: capability.summary,
              desc: capability.description,
            }
          : {
              summary: 'No capability',
              desc: [],
            },
        scopeValue,
        coverageValue,
        (scopeValue * coverageValue) / 100
      );

      return rowData;
    });

  const helpURI = '';

  const showCapability = (capability: any, rowIdx: number, style: any) => {
    if (capability.desc.some((desc: string) => desc.length > 0)) {
      return (
        <TableCell
          key={`${featureName}/tbody/row-${rowIdx}/col-1`}
          style={style}
          component="th"
          scope="row"
          align="left"
        >
          <DescriptionAccordion
            summary={capability.summary}
            details={capability.desc}
            identifier={rowIdx}
          />
        </TableCell>
      );
    } else {
      return (
        <TableCell
          key={`${featureName}/tbody/row-${rowIdx}/col-1`}
          style={style}
          component="th"
          scope="row"
          align="left"
        >
          <Typography
            variant="body2"
            style={{
              paddingLeft: '1rem',
              paddingTop: '0.8rem',
              paddingBottom: '0.8rem',
            }}
          >
            {capability.summary}
          </Typography>
        </TableCell>
      );
    }
  };

  return (
    <Paper
      elevation={3}
      style={{ padding: '1.5rem', marginTop: '2rem', marginBottom: '2rem' }}
    >
      <div style={tableContainerWithMarginStyles}>
        <Table size="small">
          <TableHead>
            <TableRow style={headerStyles}>
              <TableCell colSpan={4}>
                <Typography variant="h6">{threatSurface.name}</Typography>
              </TableCell>
              <TableCell colSpan={1} align="right" width="60px">
                <IconButton
                  disabled={!helpURI}
                  onClick={() => displayHelp(helpURI)}
                >
                  <HelpOutlineRounded />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow key="header">
              <TableCell
                key="threat-surface-matrix-threat-level"
                style={firstColStyles}
              >
                Threat Level
              </TableCell>
              <TableCell key="threat-surface-matrix-capability">
                Capability
              </TableCell>
              <TableCell key="threat-surface-matrix-scope" align="center">
                Inventory Confidence
              </TableCell>
              <TableCell key="threat-surface-matrix-coverage" align="center">
                Coverage
              </TableCell>
              <TableCell
                key="threat-surface-matrix-threat-preparedness"
                align="center"
              >
                Threat Preparedness
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) &&
              data.map((row, rowIdx) => (
                <TableRow key={`${featureName}/tbody/row-${rowIdx}`}>
                  {row.map((col: any, cellIndex: number) => {
                    let style = {};
                    if (cellIndex > 1) {
                      const barColors = getBarColorsForValue(col);
                      style = {
                        backgroundColor: barColors.background,
                        color: barColors.textColor,
                      };
                    } else if (cellIndex === 0) {
                      style = {
                        whiteSpace: 'nowrap',
                      };
                    }
                    if (cellIndex == 1) {
                      return showCapability(col, rowIdx, style);
                    } else {
                      return (
                        <TableCell
                          key={`${featureName}/tbody/row-${rowIdx}/col-${cellIndex}`}
                          style={style}
                          component="th"
                          scope="row"
                          align={cellIndex > 1 ? 'center' : 'left'}
                        >
                          {col}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default ThreatSurfaceMatrix;
