import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Zoom,
} from '@material-ui/core';
import MuiGrey from '@material-ui/core/colors/grey';
import { StripedTableRow } from 'app/common/StripedTable';
import DescriptionAccordion from 'app/common/DescriptionAccordion';
import {
  HelpOutlineRounded,
  NoteOutlined,
  NoteTwoTone,
  LinkOutlined,
} from '@material-ui/icons';
import {
  Control,
  ControlCapability,
  getControlCapabilities,
  getKPIsAndValues,
  KPIGroup,
  getKPIContext,
  KPIContext,
  KPIMetadata,
} from 'app/services/api';
import {
  ProcessedControlCapability as CapabilityType,
  processCapabilities,
} from 'app/utils/helpers';
import {
  getThreatLevels,
  getThreatSurfaces,
  ThreatLevel,
  ThreatSurface,
} from 'app/services/apiThreatSurfaces';
import Loader from 'app/common/Loader';
import NavThreatSurfaces from 'app/kpi/components/NavThreatSurfaces';
import {
  tableContainerStyles,
  headerStyles,
  subHeaderStyles,
} from 'app/kpi/components/tables';
import ValidatedInput from 'app/kpi/components/tables/ValidatedInput';
import { getCurrentUser } from 'app/services/auth';
import { KPIData } from 'app/kpi/types';
import { KPIGroupDisplay } from 'app/kpi/types/KPIGroupDisplay';
import { connect } from 'react-redux';
import { kpiGroupsState } from 'app/common/actions/kpi-groups';
import { kpiMetadataState } from 'app/common/actions/kpi-metadata';
import { buildValueInputTableConfig } from 'app/kpi/components/tables/index';
import { displayHelp, getHelpURN } from 'app/services/help';
import KPIContextDialog from 'app/kpi/components/KPIContextDialog';

interface DisplayData {
  capability: CapabilityType;
  kpiGroup: KPIGroupDisplay;
  kpiMetadata: kpiMetadataState;
}

interface KPISecurityCoverageProps {
  kpiGroups: kpiGroupsState;
  selectedControl: Control;
  kpiMetadata: kpiMetadataState;
}

type mapStateType = {
  app: {
    control: Control;
  };
  kpiGroups: kpiGroupsState;
  kpiMetadata: kpiMetadataState;
};

const KPISecurityCoverage: FunctionComponent<KPISecurityCoverageProps> = ({
  kpiGroups,
  selectedControl,
  kpiMetadata,
}: KPISecurityCoverageProps) => {
  const currentUser = getCurrentUser();
  const kpiGroupPayload = kpiGroups.payload as KPIGroup[];
  const kpiGroup = kpiGroupPayload.find(({ key }) =>
    key.includes('security-coverage')
  );
  const kpiMetadataPayload = kpiMetadata.payload as KPIMetadata[];
  const [activeSurface, setActiveSurface] = useState<ThreatSurface | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [surfaces, setSurfaces] = useState<ThreatSurface[] | null>(null);
  const [levels, setLevels] = useState<ThreatLevel[] | null>(null);
  const [displayData, setDisplayData] = useState<Map<
    number,
    DisplayData
  > | null>(null);
  const [errors] = useState<KPIData<any>[]>([]);
  const [kpiContext, setKpiContext] = useState<KPIContext[] | []>([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [contextKey, setContextKey] = useState('');

  const onContextDialogOpen = () => {
    setDialogIsOpen(true);
  };

  const handleDialogClose = () => {
    setDialogIsOpen(false);
  };

  const getData = async (surfaceId: number) => {
    if (!kpiGroup || !selectedControl) {
      return;
    }

    const caps: ControlCapability[] = await getControlCapabilities(
      selectedControl.id,
      surfaceId
    );
    const capabilities: CapabilityType[] = processCapabilities(caps);

    const kpiData = await Promise.all(
      capabilities.map((capability) =>
        getKPIsAndValues({
          groupId: kpiGroup.id,
          controlId: selectedControl.id,
          levelId: capability.levelId,
          surfaceId,
        })
      )
    );

    const context = await getKPIContext({
      key: kpiGroup.key,
      controlId: selectedControl.id,
      surfaceId,
    });
    setKpiContext(context);

    const displayData = capabilities.map((capability, index) => [
      capability.levelId,
      {
        capability,
        kpiGroup: new KPIGroupDisplay(kpiGroup, kpiData[index]),
      },
    ]);

    setDisplayData(
      new Map<number, DisplayData>(
        displayData as Iterable<[number, DisplayData]>
      )
    );
  };

  useEffect(() => {
    if (!kpiGroup || !selectedControl) {
      return;
    }

    (async () => {
      const [surfaces, levels] = await Promise.all([
        getThreatSurfaces(),
        getThreatLevels(),
      ]);
      setSurfaces(surfaces as ThreatSurface[]);
      setLevels(levels as ThreatLevel[]);
      setActiveSurface(surfaces ? surfaces[0] : null);

      await getData(surfaces[0].id);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!kpiGroup || !selectedControl) {
      return;
    }

    (async () => {
      const activeSurfaceId = activeSurface ? activeSurface.id : undefined;
      const context = await getKPIContext({
        key: kpiGroup.key,
        controlId: selectedControl.id,
        surfaceId: activeSurfaceId,
      });

      setKpiContext(context);

      setContextKey(kpiGroup.key);
    })();
  }, [dialogIsOpen]);

  const handleSurfaceSelection = async (
    surface: ThreatSurface
  ): Promise<void> => {
    setActiveSurface(surface);
    setDisplayData(null);
    await getData(surface.id);
  };

  const getDisplayData = (levelId: number): DisplayData | undefined => {
    if (!displayData) {
      return;
    }
    return displayData.get(levelId);
  };

  if (!kpiGroup || !selectedControl || !surfaces) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  let tableData;

  if (displayData) {
    tableData = (levels || []).map((level) => {
      const levelDisplayData = getDisplayData(level.id);
      if (!levelDisplayData) {
        return null;
      }

      const group = levelDisplayData.kpiGroup;
      const control = selectedControl;
      const capability = levelDisplayData.capability;

      const { columns, items } = buildValueInputTableConfig(
        group,
        control,
        [capability],
        [activeSurface as ThreatSurface],
        null,
        (kpi) => ({
          text: kpi.text,
          helpURI: getHelpURN({ context: 'kpi', resource: kpi.helpURI }),
        })
      );

      return { level, columns, items };
    });
  }

  const showCapability = (
    summary: string | undefined,
    descriptions: string[] | undefined,
    rowIdx: number
  ) => {
    if (descriptions && descriptions.some((desc: string) => desc.length > 0)) {
      let style = {};
      if (rowIdx & 1) {
        style = {
          backgroundColor: MuiGrey[100],
        };
      }
      return (
        <DescriptionAccordion
          summary={summary ? summary : ''}
          details={descriptions}
          identifier={rowIdx}
          style={style}
        />
      );
    } else {
      return (
        <Typography variant="body2" style={{ paddingLeft: '1rem' }}>
          {summary ? summary : ''}
        </Typography>
      );
    }
  };

  const showTooltips = (columnName: string) => {
    if (columnName == 'Known' || columnName == 'Inventory Confidence') {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          arrow
          placement="top"
          title={
            <React.Fragment>
              <Typography color="inherit">
                Asset inventory completeness
              </Typography>
              <Typography variant="body2">
                <br />
                {`The currently known inventory of assets (e.g., users, devices, networks, applications, data) 
                    that this security capability should be applied to is likely to be x% of the total real-world inventory.`}
                <br />
                <br />
                {'Suggested artifacts:'}
                <ul>
                  <li>{'Why we believe this to be the case'}</li>
                  <li>
                    {'Current state of our efforts to inventory these assets'}
                  </li>
                  <li>{'Asset inventory'}</li>
                  <li>{'Asset inventory owner'}</li>
                </ul>
              </Typography>
            </React.Fragment>
          }
        >
          <IconButton>
            <HelpOutlineRounded />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          arrow
          placement="top"
          title={
            <React.Fragment>
              <Typography color="inherit">
                Capability deployment completeness
              </Typography>
              <Typography variant="body2">
                <br />
                {
                  'This security capability has been applied to x% of the known asset inventory.'
                }
                <br />
                <br />
                {'Suggested artifacts:'}
                <ul>
                  <li>{'Why we believe this to be the case'}</li>
                </ul>
                <br />
                {
                  'Artifacts supporting the resources delivering the capability:'
                }
                <ul>
                  <li>{'People, roles, tasks, processes, reports'}</li>
                  <li>
                    {
                      'Techs, contracts, standards, configurations, processes, reports'
                    }
                  </li>
                  <li>
                    {'Vendors, contracts, SLAs, contacts, processes, reports'}
                  </li>
                </ul>
              </Typography>
            </React.Fragment>
          }
        >
          <IconButton>
            <HelpOutlineRounded />
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <Box display="flex" mt={2}>
      <Box>
        <Box component={Paper}>
          <NavThreatSurfaces
            activeSurface={activeSurface}
            surfaces={surfaces}
            handleSurfaceSelection={handleSurfaceSelection}
          />
        </Box>
      </Box>
      <Box ml={2} flex={1}>
        {displayData ? (
          <Paper
            elevation={3}
            style={{
              padding: '1.5rem',
              marginTop: '0.5rem',
              marginBottom: '2rem',
            }}
          >
            <div style={tableContainerStyles}>
              <Table>
                <TableHead>
                  <TableRow style={headerStyles}>
                    <TableCell colSpan={3}>
                      <Typography variant="h6">
                        {activeSurface?.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ minWidth: '160px', padding: '0.2rem' }}
                    >
                      {kpiContext && kpiContext.length !== 0 ? (
                        <IconButton onClick={() => onContextDialogOpen()}>
                          <NoteTwoTone />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => onContextDialogOpen()}>
                          <NoteOutlined />
                        </IconButton>
                      )}
                      {kpiContext &&
                        kpiContext.length !== 0 &&
                        kpiContext[0].contextURI && (
                          <IconButton
                            onClick={() =>
                              window.open(kpiContext[0].contextURI!, '_blank')
                            }
                          >
                            <LinkOutlined />
                          </IconButton>
                        )}
                      <IconButton
                        disabled={!kpiGroup.helpURI}
                        onClick={() =>
                          displayHelp({
                            context: 'kpi',
                            resource: kpiGroup.helpURI,
                          })
                        }
                      >
                        <HelpOutlineRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow style={subHeaderStyles}>
                    <TableCell style={{ width: '15%' }}>Threat Level</TableCell>
                    <TableCell>Capability</TableCell>
                    {Array.isArray(tableData) &&
                      tableData[0] &&
                      tableData[0].columns.map((col, i) => (
                        <TableCell key={i} align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Box
                              mr={1 / 2}
                              whiteSpace={col.wrap ? 'normal' : 'nowrap'}
                              style={{ padding: 0 }}
                            >
                              {col.text}
                              {showTooltips(col.text)}
                            </Box>
                          </Box>
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(tableData) &&
                    tableData.map((row, rowIdx) => (
                      <StripedTableRow
                        key={row?.level?.name}
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        <TableCell>
                          <Typography variant="body2">
                            {row?.level?.severity}. {row?.level?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {showCapability(
                            row?.items[0].text,
                            row?.items[0].description,
                            rowIdx
                          )}
                        </TableCell>
                        {row?.items[0].kpis.map((kpi, idx) => {
                          return (
                            <TableCell align="center" key={idx}>
                              <ValidatedInput
                                currentUser={currentUser}
                                column={row?.columns[idx]}
                                error={errors.includes(kpi)}
                                kpi={kpi}
                              />
                            </TableCell>
                          );
                        })}
                      </StripedTableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </Paper>
        ) : (
          <Loader />
        )}
        {dialogIsOpen && (
          <KPIContextDialog
            isOpen={dialogIsOpen}
            context={kpiContext ? kpiContext[0] : null}
            contextKey={contextKey}
            kpiMetadata={kpiMetadataPayload}
            controlId={selectedControl.id}
            surfaceId={activeSurface?.id}
            handleClose={handleDialogClose}
          />
        )}
      </Box>
    </Box>
  );
};

const mapState = ({
  app: { control },
  kpiGroups,
  kpiMetadata,
}: mapStateType) => ({
  selectedControl: control,
  kpiGroups,
  kpiMetadata,
});

export default connect(mapState)(KPISecurityCoverage);
